<template>
  <div class="contacts">
    <SkeletonListContact :count="3" title="Contacts" v-if="loading && page === 1" />
    <template v-else>
      <div class="widgetContainer__body widgetContainer--scrollable"
           v-infinite-scroll="load"
           :infinite-scroll-disabled="disabledLoadMore"
           :infinite-scroll-immediate="false"
           v-if="contacts.length !== 0">
        <p class="header-3">
          {{ $t('cotacts_list_header') }}
        </p>
        <ul>
          <li
            v-for="contact in contacts"
            :key="contact.id"
            @click="viewDetails(contact)">
            <div class="initialsInfoContact initialCaps">
              <span>{{ contact.name.charAt(0) }}</span>
            </div>
            <div class="details">
              <p class="name initialCaps">
                {{ contact.name }}
              </p>
              <p class="date initialCaps">
                {{ contact.lastName }}
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
        </ul>
        <div class="infinite-loading"
             v-if="loading && page !== 1">
          <p>Loading...</p>
        </div>
      </div>
      <div class="noData" v-else>
        <p>
          {{ $t('no_contact') }}
        </p>
      </div>
    </template>
    <Drawer v-if="type === 'contact'" class="default-drawer" :components="contactDetailsRoutes" :show.sync="contactDetailsDialog" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import contactMixins from '../mixins/contact';
import Drawer from '@/components/Drawer.vue';
import SkeletonListContact from '@/components/Skeleton/SkeletonListContact.vue';

export default {
  name: 'ContactList',
  mixins: [contactMixins],
  props: {
    type: {
      type: String,
      default: 'contact'
    },
    searchterm: {
      type: String,
      default: null
    }
  },
  components: {
    Drawer,
    SkeletonListContact
  },
  data() {
    return {
      contactDetailsDialog: false,
      loading: true,
      page: 1,
      limit: 25,
      persistItemCount: 0,
      contactDetailsRoutes: [
        {
          name: 'details',
          component: () => import('./ContactDetails.vue'),
          default: true,
          data: {
          },
          methods: {
            fetchContacts: this.getData
          }
        },
        {
          name: 'paymentType',
          component: () => import('@m/pay/views/PaymentType.vue')
        },
        {
          name: 'intraBank',
          component: () => import('@m/pay/views/Intrabank.vue')
        },
        {
          name: 'ach',
          component: () => import('@m/pay/views/Ach.vue')
        },
        {
          name: 'check',
          component: () => import('@m/pay/views/Check.vue')
        },
        {
          name: 'domestic-wire-address',
          component: () => import('@m/pay/views/DomesticWire.vue')
        },
        {
          name: 'domestic-wire-account',
          component: () => import('@m/pay/views/DomesticWireAccount.vue')
        },
        {
          name: 'international-wire-address',
          component: () => import('@m/pay/views/InternationalWire.vue')
        },
        {
          name: 'international-wire-account',
          component: () => import('@m/pay/views/InternationalWireAccount.vue')
        },
        {
          name: 'international-wire-bank-address',
          component: () => import('@m/pay/views/InternationalWireBankAddress.vue')
        },
        {
          name: 'card-payment',
          component: () => import('@m/pay/views/CardPayment.vue')
        },
        {
          name: 'pay-success',
          component: () => import('@m/pay/views/PaySuccess.vue')
        },
        {
          name: 'edit-contact',
          component: () => import('./ContactDetailForm.vue'),
          methods: {
            fetchContacts: this.getUpdatedData
          }
        },
        {
          name: 'add-contact',
          component: () => import('./ContactDetailForm.vue'),
        },
        {
          name: 'contact-success',
          component: () => import('./ContactCreatedSuccess.vue'),
          methods: {
            fetchContacts: this.getUpdatedData,
            openContactDeatilsDrawer: this.openContactDeatilsDrawer,
          }
        }
      ],
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters('contact', ['getContacts', 'getCreateContactData']),
    contacts() {
      /** @type {import('@m/contact/store/state').contactState['contacts']} */
      const contacts = this.getContacts.data;

      contacts.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      return contacts;
    },
    disabledLoadMore() {
      return (
        this.loading === true ||
        this.getContacts.total <= this.page * this.limit
      );
    }
  },
  methods: {
    ...mapActions('contact', ['listAllContacts']),
    ...mapMutations('contact', [
      'updateSelectedContactData',
      'updateCreateContactData'
    ]),
    viewDetails(contact) {
      if (this.$listeners.onContactClick) {
        this.$emit('onContactClick', contact);
        return;
      }
      this.updateSelectedContactData(contact);
      this.contactDetailsRoutes = this.contactDetailsRoutes.map((r) => {
        r.default = r.name === 'details';
        return r;
      })
      setTimeout(() => {
        this.contactDetailsDialog = true;
      }, 0)
    },
    getData() {
      this.loading = true;
      let itemlimit = this.limit;
      let offset = (this.page - 1) * this.limit;
      if(this.persistItemCount > 0) {
        itemlimit = this.persistItemCount,
        offset = 0;
      }
      this.listAllContacts({
        accountId: this.getSelectedAccount.id,
        limit: itemlimit,
        offset: offset,
        query: this.searchterm,
      })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          this.loading = false;
          this.persistItemCount = 0;
        });
    },
    getUpdatedData() {
      this.persistItemCount = this.page * this.limit;
      this.getData();
    },
    openContactDeatilsDrawer() {
      this.viewDetails(this.getCreateContactData);
    },
    load() {
      if (
        this.loading === false &&
        this.getContacts.total > this.page * this.limit
      ) {
        this.page++;
        this.getData();
      }
    },
    openContactDetails() {
      this.contactDetailsRoutes = this.contactDetailsRoutes.map((r) => {
        r.default = r.name === 'add-contact';
        return r;
      })
      setTimeout(() => {
        this.contactDetailsDialog = true;
      }, 0)
    }
  },
  watch: {
    /**
     * selected account listener
     */
    getSelectedAccount(newVal, oldVal) {
      if(newVal && oldVal && newVal.id !== oldVal.id) {
        this.getData();
      }
    },
    searchterm(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.page = 1;
        this.getData();
      }
    }
  }
};
</script>

<style lang="scss">

.contacts {
  margin-top: 17px;
  flex: 1;
  display: flex;
  overflow: hidden;

  .skeleton-list-contact {
    width: 100%;
  }

  .header-3 {
    margin-bottom: 16px;
  }

  .widgetContainer__body {
    overflow: hidden;
    overflow-y: auto;
    padding: 0 0 40px;

    > ul {
      background: #fff;
      border-radius: var(--radius);
      padding: 16px;
      border: 1px solid #eeeeee;

      li {
        padding: 16px 8px;
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        transition: 0.2s;
        cursor: pointer;
        border-bottom: 1px solid #eeeeee;

        &:first-of-type {
          padding-top: 0;
          margin: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
          margin-bottom: 0;
          border: none;
        }

        .initialsInfoContact {
          background: #f6f7f9;
          color: var(--branding-primary);
        }

        .details {
          margin: 0 auto 0 15px;
          padding-right: 8px;

        .name {
          font-size: 15px;
          line-height: 20px;
        }

        .date {
          color: rgba(#3c3c43, 0.6);
          font-size: 12px;
          padding-top: 2px;
        }
        }

        .icon {
          color: #c7c7c9;
          font-size: 13px;
        }
      }
    }
  }
  .noData {
    width: 100%;
  }
}

.zero-contact {
  white-space: pre-wrap;
  padding-top: 40px;
  color: rgba(#000000, 0.4);
  line-height: 24px;
  font-size: 16px;
  text-align: center;
}
  
</style>
