<template>
  <div class="skeleton-list-contact">
    <p class="header-3" v-if="title">
      {{ title }}
    </p>
    <el-skeleton :animated="true" :count="3">
      <template slot="template">
        <div class="skeleton-list-contact__wrapper">
          <el-skeleton-item variant="rect" />
          <el-skeleton-item variant="p" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    count: {
      type: Number,
      required: false,
      default: 3
    }
  },
}
</script>

<style lang="scss">
  .skeleton-list-contact {
    padding-top: 17px;

    .header-3 {
      margin-bottom: 16px;
    }
    
    .el-skeleton {
      width: 100%;
      margin-bottom: 24px;
      background: white;
      border-radius: 4px;
      border-top: 1px solid #eeeeee;

      &__rect {
        height: 32px; 
        width: 32px; 
        margin-right: 22px;
      }

      &__p {
        height: 20px;
        width: 100%;
        max-width: 400px;
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      padding: 24px 16px;
    }
  }
</style>